@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
  font-family: doctor-glich;
  src: url("assets/fonts/doctor_glitch/Doctor\ Glitch.otf");
}

@font-face {
  font-family: teqniya;
  src: url("assets/fonts/teqniya/4_2E.otf");
}
@font-face {
  font-family: origami;
  src: url("assets/fonts/Origami.Colored.Arabic.Font/Origami-ColorPaper.ttf");
}
@font-face {
  font-family: masterpiece;
  src: url("assets/fonts/masterpiece_2/Masterpiece.ttf");
}

@font-face {
  font-family: tech;
  src: url("assets/fonts/sdglitchdemo/SDGlitch_Demo.ttf");
}


.font-tech {
  font-family: "tech", sans-serif !important;

}

.fira-sans-black {
  font-family: "Fira Sans", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.font-origami{
  font-family: "origami" !important;

}
.font-teqniya{
  font-family: "teqniya" !important;

}


.font-cracked-code {
  font-family: "masterpiece", sans-serif !important;
}

.font-doctor-gliche {
  font-family: "doctor-glich", sans-serif !important;
}

* {
  transition-property: all;
  transition-duration: 900ms;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family:"tech", "origami"!important ;
}

#myVideo {
  position: fixed;
  position: -webkit-sticky;
  /* Safari */

  z-index: -4;
  left: 50%;
  /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */
  height: 100%;
  width: 177.77777778vh;
  /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;
  /* 100 * 9 / 16 */
  margin: 0 !important;
  padding: 0 !important;

}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.flicker-cursor {
  padding: 0px;
  margin: 0px;
  animation-name: flickering;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-play-state: running;
  animation-play-state: running;
}

.scale-on-hover {
  transform: scale(1);
  z-index: 0;

}

.scale-on-hover:hover {
  transform: scale(1.1);
  z-index: 5;

}


.carousel-item-h {
  transform: scale(1);
  outline: none;
  box-shadow: 0px 0px 0px black;
z-index: 0;}

.carousel-item-h:hover {
  transform: scale(1.3);
  z-index: 5;
  outline: gold solid 2px;
  box-shadow: 0px 0px 10px black;

}

.glow-blue {

  animation-name: glowing-text-blue;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-play-state: running;
  animation-play-state: running;
}

.glow-shadow-blue {

  animation-name: glowing-box-blue;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-play-state: running;
  animation-play-state: running;
}
.glow-white {

  animation-name: glowing-text-white;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-play-state: running;
  animation-play-state: running;
}

.color-blue {
  color: #0000FF !important;
  text-shadow: 0px 0px 20px #0000FF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(134, 131, 229);
}

.shadow-blue {
  box-shadow: 0px 0px 40px #0000FF60;
}

.color-violet {
  color: #e600ff !important;
  text-shadow: 0px 0px 20px #f200ff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #2e0082;
}
.color-violet-no-stroke {
  color: #e600ff !important;
  text-shadow: 0px 0px 20px #f200ff;
}
@keyframes flickering {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

}


@keyframes glowing-text-white {
  0% {
    text-shadow: 0px 0px 40px #0000ff00;
  }

  50% {
    text-shadow: -10px 0px 20px #ffffff;

  }

  100% {
    text-shadow: 0px 0px 20px #0000ff00;
  }

}

@keyframes glowing-text-blue {
  0% {
    text-shadow: 0px 0px 40px #0000ff00;
  }

  50% {
    text-shadow: -10px 0px 20px #0000FF;

  }

  100% {
    text-shadow: 0px 0px 20px #0000ff00;
  }

}

@keyframes glowing-box-blue {
  0% {
    box-shadow: 0px 0px 40px #0000ff00;
  }

  50% {
    box-shadow: -10px 0px 20px #0000FF;

  }

  100% {
    box-shadow: 0px 0px 20px #0000ff00;
  }

}
.bg-hover {
  box-shadow: none;
}

.bg-hover:hover {
  box-shadow: 0px 0px 50px rgb(176, 184, 255);
}