.skill-card {
    position: absolute;
    background: #000;
    left: 10%;

    transform-style: preserve-3d;
    transform: rotateY(180deg)!important;

}

.not-flipped{
    
    left: 90%;
    translate:-100%!important;
    transform: rotateY(0deg)!important;

}

.rotate-5 {
rotate: 5deg!important;
}

.rotate-n5 {
    rotate: -5deg!important;
}

.rotate-1 {
    rotate: 1deg!important;

}

.rotate-n1 {
    rotate: -1deg!important;
}

.rotate-3 {
    rotate: 3deg!important;
}

.rotate-n3 {
    rotate: -3deg!important;
}


@media only screen and (max-width: 800px) {

    .skill-card {
        position: absolute;
        background: #000;
        left: 10%;
        translate:-50%!important;
        transform-style: preserve-3d;
        transform:scale(0.6) rotateY(180deg)!important;
    
    }
    
    .not-flipped{
        
        left: 90%;
      
        translate:-100%!important;
        transform: scale(0.9) rotateY(0deg)!important;
    
    }
    }



@media only screen and (max-width: 500px) {

.skill-card {
    position: absolute;
    background: #000;
    left: 50%;
    top: 00%;

    translate:-50% -10%!important;
    transform-style: preserve-3d;
    transform:scale(0.5) rotateY(180deg)!important;

}

.not-flipped{
    
    left: 50%;
    top: 300px;
    translate:-50%!important;
    transform: scale(0.9) rotateY(0deg)!important;

}
}